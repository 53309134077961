<template>
  <div class="flex items-center justify-center">
    <div class="sm:w-max-2xl w-full mb-8">
      <div class="flex flex-row justify-between items-center mb-8">
        <span class="text-2xl font-bold" v-if="!id">Create A Product</span>
        <span class="text-2xl font-bold" v-else>Update Product</span>
        <div class="flex">
          <vs-button
            v-if="id"
            color="danger"
            @click="activeDialog = !activeDialog"
          >
            Delete
          </vs-button>
          <vs-button to="/products" class="ml-2"> Return</vs-button>
        </div>
      </div>

      <div class="flex flex-col gap-4">
        <vs-select
          v-if="games"
          placeholder="Select Game"
          v-model="selectedGame"
          block
        >
          <vs-option
            v-for="game of games"
            :key="game._id"
            :label="game.name"
            :value="game._id"
          >
            {{ game.name }}
          </vs-option>
        </vs-select>

        <vs-input placeholder="Product Name" v-model="name" block>
          <template v-if="name && !validName" #message-danger>
            <span class="text-xs">
              Product name must be between 5 and 45 characters
            </span>
          </template>
        </vs-input>

        <div>
          <Editor :value="this.$store.state.productDescription" />
        </div>

        <div>
          <vs-select
            v-if="productStatus"
            placeholder="Select Status"
            v-model="selectedStatus"
            block
          >
            <vs-option
              v-for="(status, index) of productStatus"
              :key="index.value"
              :label="status.key"
              :value="status.value"
            >
              {{ status.key }}
            </vs-option>
          </vs-select>
        </div>

        <AddExeFile></AddExeFile>

        <div class="flex flex-row gap-2">
          <vs-switch v-model="isPrivate" class="w-1/2"> Invite Only</vs-switch>
          <vs-switch
            :disabled="!isPrivate"
            v-model="allowUsersToInvite"
            class="w-1/2"
          >
            Allow users to invite
          </vs-switch>
        </div>

        <div class="flex flex-row gap-4">
          <select
            v-model="minSupportedWinBuild"
            class="select select-bordered w-full max-w-xs"
          >
            <option
              v-for="(status, index) in versions"
              :key="index"
              :value="index"
              :label="status"
            >
              {{ status }}
            </option>
          </select>
          <select
            v-model="maxSupportedWinBuild"
            class="select select-bordered w-full max-w-xs"
          >
            <option
              v-for="(status, index) in versions"
              :key="index"
              :value="index"
              :label="status"
            >
              {{ status }}
            </option>
          </select>
        </div>

        <Medias />
      </div>

      <div class="footer-dialog w-full">
        <vs-button v-if="!id" @click="createProduct()" block>
          Create
        </vs-button>
        <vs-button v-else @click="updateProduct()" block> Update</vs-button>
      </div>
    </div>

    <vs-dialog width="550px" not-center v-model="activeDialog">
      <template #header>
        <h4 class="not-margin">
          Are you sure you want to delete this product?
        </h4>
      </template>

      <template #footer>
        <div class="flex justify-end">
          <vs-button color="success" @click="deleteProduct()" transparent>
            Yes
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import {
  createProduct,
  deleteProduct,
  getAllGames,
  getMyStore,
  getProductById,
  updateProduct,
  uploadFile,
} from "@/services/ApiService";
import Medias from "@/views/seller/products/create-product/Medias";
import AddExeFile from "@/views/seller/products/create-product/AddExeFile";
import { capitalizeFirstLetter, WINDOWS_VERSIONS } from "@/core/utils.ts";
import Editor from "@/components/Editor";

export default {
  name: "CreateProduct",
  components: {
    AddExeFile,
    Medias,
    Editor,
  },
  data: () => ({
    games: null,
    store: null,
    selectedGame: "",
    selectedStatus: "",
    name: "",
    isPrivate: false,
    allowUsersToInvite: false,
    product: null,
    id: false,
    activeDialog: false,
    editor: null,
    minSupportedWinBuild: 0,
    maxSupportedWinBuild: 0,
  }),
  methods: {
    createProduct() {
      const mediaTypes = this.$store.state.mediaTypes;
      const productData = this.productData;

      if (this.$store.state.productDescription) {
        productData.description = this.$store.state.productDescription;
      }

      const noImage = this.$store.state.medias.length < 2;

      if (noImage) {
        return this.$vs.notification({
          position: "bottom-right",
          name: "Error",
          color: "danger",
          text: `You must add at least one cover, exe and image to your product`,
        });
      }

      createProduct(this.store._id, productData)
        .then((res) => {
          this.$vs.notification({
            position: "bottom-right",
            name: "Success",
            color: "success",
            text: `Product created successfully`,
          });
          const loading = this.$vs.loading({
            type: "points",
            background: "rgba(0, 0, 0, 0.6)",
            color: "primary",
          });

          const medias = this.$store.state.medias
            .filter((media) => {
              return (
                media.type === mediaTypes.image ||
                media.type === mediaTypes.cover ||
                media.type === mediaTypes.executable
              );
            })
            .map((media) => {
              return this.uploadFile(res._id, media);
            });

          Promise.all(medias)
            .then(() => {
              loading.close();
              this.$store.state.medias = [];
              this.$router.back();
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                loading.close();

                this.$vs.notification({
                  position: "bottom-right",
                  name: "Error",
                  color: "danger",
                  text: `${error.response.data.message}`,
                  duration: 5000,
                });
              }
            });
        })
        .catch((error) => {
          this.$vs.notification({
            position: "bottom-right",
            name: "Error",
            color: "danger",
            text: `${error.response.data.message}`,
          });
        });
    },
    updateProduct() {
      const mediaTypes = this.$store.state.mediaTypes;
      const productData = this.productData;

      if (this.$store.state.productDescription)
        productData.description = this.$store.state.productDescription;

      const filtered = this.$store.state.medias.filter(
        (media) => media.type === mediaTypes.video
      );

      if (filtered.length > 0) {
        productData.medias = filtered;
      }

      if (this.videos.length > 0 && !filtered.length) {
        productData.medias = [];
      }

      updateProduct(this.id, productData)
        .then(() => {
          this.$vs.notification({
            position: "bottom-right",
            name: "Success",
            color: "success",
            text: `Product updated successfully`,
          });

          const loading = this.$vs.loading({
            type: "points",
            background: "rgba(0, 0, 0, 0.6)",
            color: "primary",
          });

          const medias = this.$store.state.medias
            .filter((media) => {
              return (
                media.type === mediaTypes.image ||
                media.type === mediaTypes.cover ||
                media.type === mediaTypes.executable
              );
            })
            .map((media) => {
              if (this.$store.state.deletedMedias.find((m) => m === media.id))
                return;
              if (media.id) return;

              return this.uploadFile(this.id, media);
            });

          Promise.all(medias)
            .then(() => {
              loading.close();
              this.$store.state.medias = [];
              this.$store.state.deletedMedias = [];
              this.$router.back();
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                loading.close();

                this.$vs.notification({
                  position: "bottom-right",
                  name: "Error",
                  color: "danger",
                  text: `${error.response.data.message}`,
                  duration: 5000,
                });
              }
            });
        })
        .catch((error) => {
          this.$vs.notification({
            position: "bottom-right",
            name: "Error",
            color: "danger",
            text: `${error.response.data.message}`,
          });
        });
    },
    getCurrentProduct() {
      const mediaTypes = this.$store.state.mediaTypes;
      getProductById(this.$route.params.id).then((res) => {
        const product = res;
        this.id = product._id;
        this.name = product.name;
        this.$store.state.productDescription = product.description;
        this.selectedGame = product.game;
        this.isPrivate = product.private;
        this.allowUsersToInvite = product.allowUsersToInvite;
        this.videos = product.medias.filter(
          (media) => media.type === mediaTypes.video
        );
        this.selectedStatus = product.status;
        this.minSupportedWinBuild = product.minSupportedWinBuild;
        this.maxSupportedWinBuild = product.maxSupportedWinBuild;

        const filteredCover = product.medias.filter(
          (media) => media.type === mediaTypes.cover
        );
        const filteredImages = product.medias.filter(
          (media) => media.type === mediaTypes.image
        );

        product.medias.forEach((media) => {
          if (media.type === mediaTypes.video) {
            this.$store.state.medias.push({
              type: mediaTypes.video,
              url: media.url,
              source: media.source,
            });
          }
        });

        if (!filteredCover.length || !filteredImages.length || !product.file) {
          this.$vs.notification({
            position: "bottom-right",
            color: "danger",
            text: `Please upload a cover, image and exe file to have it ready`,
          });
        }
      });
    },
    async uploadFile(id, media) {
      const formData = new FormData();
      formData.append("file", media.file);

      const obj = Object.keys(this.$store.state.mediaTypes).map((key) =>
        key.toUpperCase()
      );
      const type = obj[media.type];

      let response = await uploadFile(id, type, formData);
      if (response) {
        this.$vs.notification({
          position: "bottom-right",
          name: "Success",
          color: "success",
          text: `${capitalizeFirstLetter(type)} uploaded successfully`,
        });

        return true;
      } else {
        this.$vs.notification({
          position: "bottom-right",
          name: "Error",
          color: "danger",
          text: `${this.capitalizeFirstLetter(type)} upload failed`,
        });
      }
    },
    deleteProduct() {
      deleteProduct(this.id)
        .then(() => {
          this.$vs.notification({
            position: "bottom-right",
            name: "Success",
            color: "success",
            text: `Product deleted successfully`,
          });

          this.$store.state.medias = [];
          this.$router.back();
        })
        .catch((error) => {
          this.$vs.notification({
            position: "bottom-right",
            name: "Error",
            color: "danger",
            text: `${error.response.data.message}`,
          });
        });
    },
  },
  async beforeCreate() {
    this.$store.state.productDescription = "";
    this.$store.state.medias = [];
    // check params
    const promises = [
      (this.games = await getAllGames(true)),
      (this.store = await getMyStore()),
    ];

    const { id } = this.$route.params;
    if (id) {
      promises.push(await this.getCurrentProduct());
      // promises.push(await this.getFile(id));
    }

    await Promise.all(promises);
  },
  watch: {
    isPrivate(val) {
      if (!val) {
        this.allowUsersToInvite = false;
      }
    },
  },
  computed: {
    productStatus() {
      const status = Object.keys(this.$store.state.productStatus);
      return status.map((key) => {
        return {
          key: key,
          value: this.$store.state.productStatus[key],
        };
      });
    },
    validName() {
      return this.name && this.name.length > 5 && this.name.length < 46;
    },
    validDescription() {
      return (
        this.description &&
        this.description.length > 0 &&
        this.description.length < 256
      );
    },
    productData() {
      const mediaTypes = this.$store.state.mediaTypes;
      return {
        name: this.name,
        game: this.selectedGame,
        private: this.isPrivate,
        allowUsersToInvite: this.allowUsersToInvite,
        medias: this.$store.state.medias.filter(
          (media) => media.type === mediaTypes.video
        ),
        status: this.selectedStatus,
        minSupportedWinBuild: Number(this.minSupportedWinBuild),
        maxSupportedWinBuild: Number(this.maxSupportedWinBuild),
      };
    },
    versions() {
      return WINDOWS_VERSIONS;
    },
  },
};
</script>
